import React, { Component } from 'react'
import Landing from '../../../../../components/case-studies/Landing'
import AboutProject from '../../../../../components/case-studies/AboutProject'
import PagesShowcase from '../../../../../components/case-studies/PagesShowcase'
import Footer from '../../../../../components/case-studies/Footer'
import Layout from '../../../../../components/layout'
import { removeNumberPrefix } from '../../../../../utilities'
import MobileCarousel from '../../../../../components/case-studies/MobileCarousel'
import { graphql } from 'gatsby'


class Letenky extends Component {
  render() {
    const { data } = this.props

    return (
      <Layout showBackButton={true}>
        <Landing
          title={'Player Test'}
          description={"Prípadová štúdia"}
          description2={'Firemná identita a dizajn pre portál, pomahajúci ľuďom získať viac informácií o dostupných hypotékach na trhu na Slovenskom trhu bez zbytočného hľadanie na googli.'}
          text={'Po konzultáciach s klientom sme pripravili 3 jednoduché firemné princípy, ktoré by mal nový portál prezentovať nie len dizajnom ale aj textami, fotkami a inými komunikačno-vizuálnymi kanálmi.'}
          img={data.landingImage}
          pageMode={true}
          noShadow={true}
          what_we_done={[
            { text: 'Logo' },
            { text: 'Korporátna identita' },
            { text: 'Vytvorenie responzívnej HTML šablon' },
            { text: 'Programovanie' }
          ]} />

        <AboutProject
          text={'Spolupráca s Tatry Golf residence park na vytvorenie nového firemného štýlu a stránky pre online portál.'}
          list={[
            {
              title: 'Zadanie',
              text: 'V máji roku 2017 sa nám ozvala spoločnost Allriskslovakia s ktorou sme spolupracovali už aj na iných projektov pre vytvorenie nového vizuálneho štýlu pre ich nový projekt hypotéka.sk',
            },
            {
              title: 'Riešenie',
              text: 'Vytvoriť vizuálny štýl portálu, pripraviť stránku fron-end ready, ktorá by imponoval na cieľovú skupinu hľadajúcu viac informácií o tom čo je hypotéka, ako sa vybavuje a ako je ju môžné získať. ',
            },
            {
              title: 'Výsledok',
              text: 'Úzko sme spolupracovali s majiteľmi portálu, ktorí mali predstavu o tom ako by nový štýl mal vyzerať ale pritom stále ponechávali dostatok kreativity a možnosti pre dizajnerá na vytvorenie nového štýlu. Po úspečných dizajnových sprintoch sme s tránku front-endovo nakódovali a odovzdali v Novembry 2017.',
            },
            {
              title: 'Ako to prebiehalo',
              text: 'Dizajnová stránka projektu bola rozdelená do týždenných sprintov, ktoré boli odprezentované a odkumunikované. Následne projekt bol prevzaty front-end developom, ktorého sme poskytli, ktorý v spolupráci s dizajnérom po 3 týždňoch odovzdal projekt klientovy na úspešne spustenie.',
            }
          ]}
        />

        <PagesShowcase
          title={'Všetko v jednom'}
          description={'Webstránka s novou identitou'}
          text={'Nový dizajn a firemná identita stránky hypotéka.sk zahŕňa všetko čo by mala novodobá stránka spĺňať. Jednoduchosť, krása a jedinečnosť sú len jednou časťou ale tou hlavnou je samotný obsah stránky, ktorý musí byť stredobodom pre uživateľa aby vypovedal to čo je potrebné.'}
          projectDetail={'/nase-prace/webstranky/eshopy/digihry-(playerland)'}//TODO
          pages={data.pages.nodes.map(node => ({ image: node, name: removeNumberPrefix(node.name) }))} />

        <MobileCarousel items={data.mobileGallery.nodes} />

        {/* <ClientReference
          text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
          clientName={'David Kopecký'}
          clientRole={'Majiteľ Allriskslovakia'}
          clientPhoto={data.clientPhoto}
        /> */}

        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          leftTopTitle='project'
          nextProjectName={'Spusti.tv'}
          nextProjectUrl={'/nase-prace/pripadove-studie/webstranky/microsite/spusti-web'}
        />
      </Layout>
    )
  }
}


export const query = graphql`{
    landingImage: file(relativePath: { eq: "case-studies/playtest/visual.png" }) {
        childImageSharp {
            fluid( maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pages: allFile(filter: { relativePath: {regex: "/(case-studies/playtest/pages/)/"} }, sort: {fields:name}) {
        nodes {
            name
            childImageSharp {
                fluid( maxWidth: 1600) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
    mobileGallery: allFile(filter: { relativePath: {regex: "/(case-studies/playtest/mobileGallery/)/"} }, sort: {fields:name}) {
        nodes {
            childImageSharp {
                fluid( maxWidth: 320) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
}
`

export default Letenky
